<template>
  <div class="index">
    <default-view />
  </div>
</template>

<script>
export default {
  name: "ConnectionLayout",
  components: {
    DefaultView: () => import ('./View'),
  }
}
</script>

<style scoped>
@media (max-width: 1000px) {
  .index{
    height: 100%;
  }
}
</style>
